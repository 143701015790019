import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from "../../components/Layout";
import DataTable from "../../components/DataTable";
import Button from "../../components/Button";
import { fetchBidList, activateDeactivateBid } from "../../api/bid";
import '../../css/Bid.css';
import { format } from "date-fns";

const Bid = () => {
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const navigate = useNavigate();

  const refreshTable = () => setRefreshTrigger((prev) => !prev);

  const handleStatusClick = async (id, isActive) => {
    await activateDeactivateBid(id);
    setRefreshTrigger((prev) => !prev);
  };

  const columns = React.useMemo(() => [
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Published Date',
      accessor: 'publishedDate',
      Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd'),
    },
    {
      Header: 'Deadline',
      accessor: 'deadline',
      Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd'),
    },
    {
      Header: 'Entity Name',
      accessor: 'entityName',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Bid Security',
      accessor: 'bidSecurity',
      Cell: ({ value }) => `${value}`,
    },
    {
      Header: 'Active',
      accessor: 'isActive',
      Cell: ({ row: { original } }) => (
        <span
          className={original.isActive ? 'active-status' : 'inactive-status'}
          onClick={() => handleStatusClick(original.id, original.isActive)}
        >
          {original.isActive ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row: { original } }) => (
        <div>
          <Button onClick={() => navigate(`/bid/${original.id}`, { state: { bid: original } })}>
            View Details
          </Button>
        </div>
      ),
    },
  ], []);

  return (
    <Layout>
      <div className="bid-page">
        <Button title=" Bid" onClick={() => navigate('/bids/add')} >Add Bid</Button>
        <DataTable columns={columns} fetchData={fetchBidList} refreshTrigger={refreshTrigger} />
      </div>
    </Layout>
  );
};

export default Bid;
