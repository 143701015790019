import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AddOrUpdateBid from "../../components/bid/AddorUpdateBid";
import Layout from "../../components/Layout";

const AddOrUpdateBidPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const bidData = location.state?.bid || null;

  const handleClose = () => {
    navigate('/bid', { state: { refresh: true } });
  };

  const handleRefresh = () => {
    navigate('/bid', { state: { refresh: true } });
  };

  return (
    <Layout>
      <AddOrUpdateBid bidData={bidData} onClose={handleClose} onRefresh={handleRefresh} />
    </Layout>
  );
};

export default AddOrUpdateBidPage;
