import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { submitBid, updateBid } from "../../api/bid";
import Button from "../../components/Button";
import "../../css/Bid.css";

const AddOrUpdateBid = ({ bidData = {}, onClose, onRefresh }) => {
    const [formData, setFormData] = useState({
      id: bidData?.id || "",
      title: bidData?.title || "",
      description: bidData?.description || "",
      publishedDate: bidData?.publishedDate || "",
      deadline: bidData?.deadline || "",
      entityName: bidData?.entityName || "",
      procurementType: bidData?.procurementType || "",
      location: bidData?.location || "",
      bidSecurity: bidData?.bidSecurity || "",
      link: bidData?.link || "",
      bidKey: bidData?.bidKey || "",
      document: null,
    });
  

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, document: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const payload = new FormData();
      Object.keys(formData).forEach((key) => {
        if (formData[key] !== null) {
          payload.append(key, formData[key]);
        }
      });

      if (formData.id) {
        // await updateBid(payload); // API for updating
      } else {
        await submitBid(payload); // API for creating
        Navigate('/bid');
      }

      alert("Bid submitted successfully!");
      onClose();
      onRefresh();
    } catch (error) {
      console.error("Error submitting bid:", error);
    //   alert("Failed to submit bid. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bid-form">
      <h2>{formData.id ? "Update Bid" : "Add Bid"}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label>Published Date</label>
          <input
            type="date"
            name="publishedDate"
            value={formData.publishedDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Deadline</label>
          <input
            type="date"
            name="deadline"
            value={formData.deadline}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Entity Name</label>
          <input
            type="text"
            name="entityName"
            value={formData.entityName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Procurement Type</label>
          <input
            type="text"
            name="procurementType"
            value={formData.procurementType}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Location</label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Bid Security</label>
          <input
            type="number"
            name="bidSecurity"
            value={formData.bidSecurity}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Link</label>
          <input
            type="text"
            name="link"
            value={formData.link}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Bid Key</label>
          <input
            type="text"
            name="bidKey"
            value={formData.bidKey}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Document</label>
          <input type="file" name="document" onChange={handleFileChange} />
        </div>
        <div className="form-actions">
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddOrUpdateBid;
