import React from 'react';

const BidDetails = ({ bid }) => {
  return (
    <div style={{ margin: '20px', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
      <h2>Bid Details</h2>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
          <tr>
            <td style={tableCellStyle}><strong>BID ID:</strong></td>
            <td style={tableCellStyle}>{bid.bidKey}</td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Title:</strong></td>
            <td style={tableCellStyle}>{bid.title}</td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Description:</strong></td>
            <td style={tableCellStyle}>{bid.description}</td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Published Date:</strong></td>
            <td style={tableCellStyle}>{new Date(bid.publishedDate).toLocaleDateString()}</td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Deadline:</strong></td>
            <td style={tableCellStyle}>{new Date(bid.deadline).toLocaleDateString()}</td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Entity Name:</strong></td>
            <td style={tableCellStyle}>{bid.entityName}</td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Procurement Type:</strong></td>
            <td style={tableCellStyle}>{bid.procurementType}</td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Location:</strong></td>
            <td style={tableCellStyle}>{bid.location}</td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Bid Security:</strong></td>
            <td style={tableCellStyle}>{bid.bidSecurity.toFixed(2)}</td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Link:</strong></td>
            <td style={tableCellStyle}>
              <a href={bid.link} target="_blank" rel="noopener noreferrer">
                View Link
              </a>
            </td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Document:</strong></td>
            <td style={tableCellStyle}>
              <a href={bid.document} download>
                Download Document
              </a>
            </td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Active:</strong></td>
            <td style={tableCellStyle}>{bid.isActive ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td style={tableCellStyle}><strong>Created At:</strong></td>
            <td style={tableCellStyle}>{new Date(bid.createdAt).toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const tableCellStyle = {
  padding: '10px',
  border: '1px solid #ddd',
};

export default BidDetails;
