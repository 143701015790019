// src/api/fetchAdvertisementList.js
import { API_BASE_URL, endpoints } from "../utils/API";
import axios from "axios";

export const fetchBidList = async (pagination) => {
  const { page, pageSize, sort, sortParameter } = pagination;
  const token = localStorage.getItem('token');

  try {
    const response = await axios.get(`${API_BASE_URL}${endpoints.bidList}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        pageSize,
        sort,
        sortParameter,
      },
    });

    // No need to call .json() on axios response
    return response.data;
  } catch (error) {
    console.error('Error fetching Bid list:', error);
    return { data: [], totalPage: 1 }; // Return default structure on error
  }
};

export const activateDeactivateBid = async (id) => {
  try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}${endpoints.activeBid}/${id}`, {
          headers:{
              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to activate Bid');
      }

      return response.data;
  } catch (error) {
      console.error('Error activating Bid:', error);
      throw error;
  }
};


export const submitBid = async (formData,action) => {
  try { const token = localStorage.getItem('token');
  const url = `${endpoints.createBid}` ;
      const response = await axios.post(`${API_BASE_URL}${url}`, formData,{
          headers:{
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`,
            },
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      console.log('Response Status:', response.status);
      console.log('Response Data:', response.data);

      return response;
  } catch (error) {
      console.error('Error submitting Bid:', error);
      throw error;
  }
};