import React from 'react';
import { useLocation } from 'react-router-dom';
import BidDetails from './BidDetails';

const BidDetailScreen = () => {
  const location = useLocation();
  const { bid } = location.state || {};

  if (!bid) {
    return <div style={{ padding: '20px' }}>No bid data available.</div>;
  }

  return <BidDetails bid={bid} />;
};

export default BidDetailScreen;
